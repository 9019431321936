<template>
  <v-app>
    <v-app-bar
        app
        color="white"
        prominent
        flat
        hide-on-scroll
    >
      <router-link to="/" class="pa-5">
        <v-img
            id="logo"
            alt="IOG Logo"
            class="d-flex shrink justify-start"
            contain
            src="/img/iog logo.png"
            transition="scale-transition"
            max-width="200px"
            to="/"
            aria-label="Home"
        />
      </router-link>
      <v-spacer class="hidden-md-and-up"></v-spacer>
      <div class="hidden-md-and-up">
        <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="primary"
                v-bind="attrs"
                v-on="on"
                class="ma-5"
            >
              <v-icon>mdi-menu</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item to="/entdecken">
              <v-list-item-title>Projekte</v-list-item-title>
            </v-list-item>
            <v-list-item to="/mitmachen">
              <v-list-item-title>Mitmachen</v-list-item-title>
            </v-list-item>
            <v-list-item to="/join">
              <v-list-item-title>Beitreten</v-list-item-title>
            </v-list-item>
            <v-list-item to="/spenden">
              <v-list-item-title>Spenden</v-list-item-title>
            </v-list-item>
            <v-list-item to="/ueberIOG">
              <v-list-item-title>Über IOG</v-list-item-title>
            </v-list-item>
            <v-list-item to="/iog_section">
              <v-list-item-title>Regionalgruppen</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <v-spacer class="hidden-sm-and-down"></v-spacer>
      <v-toolbar-title>
        <v-tabs class="hidden-sm-and-down" optional>
          <v-tab to="/entdecken">Projekte</v-tab>
          <v-tab to="/mitmachen">Mitmachen</v-tab>
          <v-tab to="/join">Beitreten</v-tab>
          <v-tab to="/spenden">Spenden</v-tab>
          <v-tab to="/ueberIOG">Über IOG</v-tab>
          <v-tab to="/iog_section">Regionalgruppen</v-tab>
        </v-tabs>
      </v-toolbar-title>
    </v-app-bar>

    <v-main>
      <router-view />
      <cookie-notification/>
    </v-main>
    <v-container>
      <div class="rtl">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                fixed
                bottom
                right
                color="secondary"
                slot="activator"
                @click="dialog = !dialog"
                to="/spenden"
                class="topButton"
                v-bind="attrs" v-on="on"
                elevation="12"
            >
              Spenden
            </v-btn>
          </template>
          <span>Spenden</span>
        </v-tooltip>
      </div>
    </v-container>
    <v-footer
        color="primary"
        padless
    >
      <v-row justify="center" no-gutters>
        <v-col cols="12" class="text-center">
          <v-btn id="facebook" aria-label="Facebook" class="pt-3 mx-4 white--text" icon target="_blank"
                 href="https://www.facebook.com/ingenieureohnegrenzenaustria/">
            <v-icon aria-hidden="false" aria-label="Facebook" role="button" size="24px">mdi-facebook</v-icon>
          </v-btn>
          <v-btn id="youtube" class="pt-3 mx-4 white--text" aria-label="YouTube" icon target="_blank"
                 href="https://www.youtube.com/channel/UCLpN9vYC4lrxT8wPT0-2E0Q">
            <v-icon aria-hidden="false" aria-label="YouTube" role="button" size="24px">mdi-youtube</v-icon>
          </v-btn>
          <v-btn id="twitter" class="pt-3 mx-4 white--text" aria-label="Twitter" icon target="_blank"
                 href="https://twitter.com/iogaustria">
            <v-icon aria-hidden="false" role="button" aria-label="Twitter" size="24px">mdi-twitter</v-icon>
          </v-btn>
          <v-btn id="instagram" class="pt-3 mx-4 white--text" aria-label="Instagram" icon target="_blank"
                 href="https://www.instagram.com/iog_austria">
            <v-icon aria-hidden="false" role="button" aria-label="Instagram" size="24px">mdi-instagram</v-icon>
          </v-btn>
          <v-btn id="linkedin" class="pt-3 mx-4 white--text" aria-label="Linkedin" icon target="_blank"
                 href="https://at.linkedin.com/company/ingenieure-ohne-grenzen-austria">
            <v-icon aria-hidden="false" role="button" aria-label="Linkedin" size="24px">mdi-linkedin</v-icon>
          </v-btn>
        </v-col>
        <v-btn color="white" text rounded class="my-2" to="/impressum">Impressum</v-btn>
        <v-btn color="white" text rounded class="my-2" to="/datenschutzerklaerung">Datenschutzerklärung</v-btn>
        <newsletter-abonnieren/>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import 'vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css';
import CookieNotification from './components/CookieNotification';
import NewsletterAbonnieren from './components/NewsletterAbonnieren';

export default {
  name: 'App',
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Ingenieur:innen ohne Grenzen Austria',
    // all titles will be injected into this template
    htmlAttrs: {
      lang: 'de-AT'
    },
    meta: [
      {charset: 'utf-8'},
      {
        name: 'description',
        content: 'Wir sind ein gemeinnütziger, österreichweiter Verein, mit dem Zweck Projekte in der technischen Entwicklungszusammenarbeit durchzuführen. Unsere Mitglieder und Aktiven sind Menschen aller Altersgruppen aus den unterschiedlichsten Studienrichtungen und Berufsfeldern, die alle ehrenamtlich mitarbeiten.'
      },
      {name: 'viewport', content: 'width=device-width, initial-scale=1'}
    ]
  },
  components: {NewsletterAbonnieren, CookieNotification},

  data: () => ({
    icons: [
      'mdi-facebook',
      'mdi-twitter',
      'mdi-instagram',
    ],
  }),
  methods: {},
}
;
</script>

<style>
/* clear */
.clear:before,
.clear:after {
  content: ' ';
  display: table;
}

.clear:after {
  clear: both;
}

.clear {
  *zoom: 1;
}

img {
  max-width: 100%;
  vertical-align: bottom;
}

a {
  color: #444;
  text-decoration: none;
}

a:hover {
  color: #444;
}

a:focus {
  outline: 0;
  text-decoration: none;
}

a:hover,
a:active {
  text-decoration: none;
  outline: 0;
}

input:focus {
  outline: 0;
  border: 1px solid #04A4CC;
}

body {
  font-family: 'Roboto', 'Calibri', sans-serif;
  font-weight: 400;
}

h1,
h2,
h3 {
  font-family: 'Lato', 'Roboto', 'Calibri', sans-serif;
  font-weight: 700;
  color: #f7a600;
}

h1 {
  text-transform: uppercase;
  font-size: 3em;
}

.nav a {
  padding-right: 1em;
  text-decoration-line: none;
}

.nav a:hover {
  color: white;
}

h1, h2, h3, h4, h5, h6 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}

h1, h2, h3 {
  font-family: 'Lato', 'Roboto', 'Calibri', sans-serif;
  font-weight: 700;
  color: #f7a600;
}

h2, h3 {
  font-weight: 600;
}

h3 {
  font-size: 24px;
  margin-top: 50px;
  margin-bottom: 50px;
  text-transform: uppercase;
  color: black;
  letter-spacing: 2px;
}

.rtl {
  direction: rtl;
}

.topButton {
  z-index: 9000;
}

</style>