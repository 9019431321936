<template>
  <v-container fill-height fluid ref="layoutBox">
    <v-col justify="center" align="center" style="padding: 0; margin: 0;">
      <LazyYoutube src="https://www.youtube.com/embed/nU-g0BIAv2M?autoplay=1&loop=1"
                   autoplay
                   :maxWidth="maxVideoWidth"
                   ref="video"/>
    </v-col>
  </v-container>
</template>

<script>
import {LazyYoutube} from "vue-lazytube";

export default {
  name: 'Video',
  components: {
    LazyYoutube,
  },
  props: {
    path: String,
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.matchHeight);
    })
    this.matchHeight();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.matchHeight);
  },
  data() {
    return {
      loading: false,
      page: null,
      error: null,
      header_image: null,
      maxVideoWidth: null,
    }
  },
  methods: {
    matchHeight() {
      let maximumElementHeight = this.$refs.layoutBox.clientHeight - 32;
      let maxVideoWidth = maximumElementHeight / 9 * 16;
      this.maxVideoWidth = maxVideoWidth + "px";
    }
  }
}
</script>
<style scoped>

</style>