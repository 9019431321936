import Vue from 'vue';
import VueRouter from 'vue-router';
//import Slider from '../components/Slider';
import Video from '../views/Video';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Video',
    props: {name: 'Home'},
    component: Video
  },
  {
    path: '/entdecken',
    name: 'Entdecken',
    component: () => import(/* webpackChunkName: "slider" */ '../views/Projects.vue')
  },
  {
    path: '/mitmachen',
    name: 'Mitmachen',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    props: {path: 'mitmachen'},
    component: () => import(/* webpackChunkName: "mitmachen" */ '../views/Page.vue')
  },
  {
    path: '/subscribed',
    name: 'Erfolgreich angemeldet',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    props: {path: 'subscribed'},
    component: () => import(/* webpackChunkName: "subscribed" */ '../views/Page.vue')
  },
  {
    path: '/impressum',
    name: 'Impressum',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    props: {path: 'impressum'},
    component: () => import(/* webpackChunkName: "impressum" */ '../views/Page.vue')
  },
  {
    path: '/datenschutzerklaerung',
    name: 'Datenschutzerklärung',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    props: {path: 'datenschutzerklaerung'},
    component: () => import(/* webpackChunkName: "datenschutzerklaerung" */ '../views/Page.vue')
  },
  {
    path: '/spenden',
    name: 'Unterstützen',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    props: {path: 'spenden'},
    component: () => import(/* webpackChunkName: "spenden" */ '../views/Page.vue')
  },
  {
    path: '/join',
    name: 'Beitreten',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    props: {path: 'join'},
    component: () => import(/* webpackChunkName: "join" */ '../views/Join.vue')
  },
  {
    path: '/ueberIOG',
    name: 'Über IOG',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    props: {path: 'ueberIOG'},
    component: () => import(/* webpackChunkName: "ueberIOG" */ '../views/Page.vue')
  },
  {
    path: '/iog_section',
    name: 'Sections',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    props: {path: 'iog_section'},
    component: () => import(/* webpackChunkName: "iog_section" */ '../views/Sections.vue')
  },
  {
    path: '/iog_project/:path',
    name: 'Project',
    component: () => import(/* webpackChunkName: "iog_project" */ '../views/Project.vue')
  },
  {
    path: '/iog_section/:name',
    name: 'Section',
    component: () => import(/* webpackChunkName: "iog_section" */ '../views/Section.vue')
  },
  {
    path: '/spenden',
    name: 'Spenden',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    props: {path: 'spenden'},
    component: () => import(/* webpackChunkName: "spenden" */ '../views/Page.vue')
  },
  {
    path: '/zusage',
    name: 'Zusage',
    props: {path: 'zusage'},
    component: () => import(/* webpackChunkName: "ueberIOG" */ '../views/Rsvp.vue')
  },
  {
    path: '/absage',
    name: 'Absage',
    props: {path: 'absage'},
    component: () => import(/* webpackChunkName: "ueberIOG" */ '../views/Rsvp.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
