<template>
  <vue-cookie-accept-decline
      id="cookie"
      :elementId="'myPanel1'"
      :debug="false"
      :position="'top'"
      :type="'floating'"
      :disableDecline="false"
      :transitionName="'slideFromBottom'"
      :showPostponeButton="false"
      @clicked-accept="cookieClickedAccept"
      @clicked-decline="deleteCookies">

    <!-- Optional -->
    <div slot="postponeContent">
      &times;
    </div>

    <!-- Optional -->
    <div slot="message">
      Diese Seite verwendet Cookies. Diese dienen dazu, die Funktionalität dieser Website zu gewährleisten sowie die
      Nutzung der Website zu analysieren.
      Nähere Informationen finden Sie in <a href="/datenschutzerklaerung">unserer Datenschutzerklärung.</a>
    </div>

    <!-- Optional -->
    <div slot="declineContent">
      Ablehnen
    </div>

    <!-- Optional -->
    <div slot="acceptContent">
      Akzeptieren
    </div>
  </vue-cookie-accept-decline>
</template>
<script>
import VueCookieAcceptDecline from 'vue-cookie-accept-decline';

export default {
  name: 'cookie-notification',
  components: {VueCookieAcceptDecline},
  props: {},
  methods: {
    deleteCookies() {
      let theCookies = document.cookie.split(';');
      for (let i = 0; i < theCookies.length; i++) {
        document.cookie = theCookies[i].split('=')[0] + '=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      }
    },
    cookieClickedAccept() {
      console.log('Cookies accepted');
    }
  },
};
</script>
<style>
/* clear */

.cookie {
  z-index: 9999;
}

img {
  max-width: 100%;
  vertical-align: bottom;
}

a {
  color: #444;
  text-decoration: none;
}

a:hover {
  color: #444;
}

a:focus {
  outline: 0;
  text-decoration: none;
}

a:hover,
a:active {
  text-decoration: none;
  outline: 0;
}

input:focus {
  outline: 0;
  border: 1px solid #04A4CC;
}

body {
  font-family: 'Roboto', 'Calibri', sans-serif;
  font-weight: 400;
}

h1,
h2,
h3 {
  font-family: 'Lato', 'Roboto', 'Calibri', sans-serif;
  font-weight: 700;
  color: #f7a600;
}

h1 {
  text-transform: uppercase;
  font-size: 3em;
}

.nav a {
  padding-right: 1em;
  text-decoration-line: none;
}

.nav a:hover {
  color: white;
}

h1, h2, h3, h4, h5, h6 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}

h1, h2, h3 {
  font-family: 'Lato', 'Roboto', 'Calibri', sans-serif;
  font-weight: 700;
  color: #f7a600;
}

h2, h3 {
  font-weight: 600;
}

h3 {
  font-size: 24px;
  margin-top: 50px;
  margin-bottom: 50px;
  text-transform: uppercase;
  color: black;
  letter-spacing: 2px;
}

</style>