import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import vuetify from './plugins/vuetify';
import VueIframe from 'vue-iframes';
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@fontsource/lato/300.css";
import "@fontsource/lato/400.css";
import "@fontsource/lato/700.css";// Defaults to weight 400.
import Meta from 'vue-meta';

Vue.config.productionTip = false;

Vue.use(VueIframe);
Vue.use(Meta);

new Vue({
    router,
    vuetify,
    render: h => h(App)
}).$mount('#app');