<template>
  <v-dialog transition="dialog-bottom-transition" v-model="newsletterDialog" max-width="500px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="white" text rounded class="my-2" v-bind="attrs" v-on="on">Newsletter abonnieren</v-btn>
    </template>
    <template>
      <v-card>
        <v-toolbar color="primary" dark>
          <v-icon large class="mr-4">mdi-email-outline</v-icon>
          <h3 style="color: white">Newsletter abonnieren</h3>
        </v-toolbar>
        <v-card-text class="pa-2">
          <v-spacer></v-spacer>
          <v-form ref="form" v-model="valid" lazy-validation class="pa-2">
            <v-text-field
                v-model="firstName"
                :rules="firstNameRules"
                label="Vorname"
                required
            ></v-text-field>
            <v-text-field
                v-model="lastName"
                :rules="lastNameRules"
                label="Nachname"
                required
            ></v-text-field>

            <v-text-field
                v-model="email"
                :rules="emailRules"
                label="E-Mail"
                required
            ></v-text-field>
            <div>Abonnieren Sie auch Infos aus den Regionalgruppen</div>
            <v-row class="ml-2 mr-2 mt-2 mb-0 pa-0" dense>
              <v-col class="ml-2 mr-2 mt-0 mb-0 pa-0">
                <v-checkbox v-model="regionalGruppen[0].checked" class="shrink mr-0 mt-0"
                            :label="regionalGruppen[0].name"></v-checkbox>
              </v-col>
              <v-col class="ml-2 mr-2 mt-0 mb-0 pa-0">
                <v-checkbox v-model="regionalGruppen[1].checked" class="shrink mr-0 mt-0"
                            :label="regionalGruppen[1].name"></v-checkbox>
              </v-col>
            </v-row>
            <v-row class="ml-2 mr-2 mt-0 mb-0 pa-0" dense>
              <v-col class="ml-2 mr-2 mt-0 mb-0 pa-0">
                <v-checkbox v-model="regionalGruppen[2].checked" class="shrink mr-0 mt-0"
                            :label="regionalGruppen[2].name"></v-checkbox>
              </v-col>
              <v-col class="ml-2 mr-2 mt-0 mb-0 pa-0">
                <v-checkbox v-model="regionalGruppen[3].checked" class="shrink mr-0 mt-0"
                            :label="regionalGruppen[3].name"></v-checkbox>
              </v-col>
            </v-row>

            <v-checkbox
                v-model="checkbox"
                :rules="[v => !!v || 'Sie müssen zustimmen um fortzufahren.']"
                label="Ich möchte laufend aktuelle Information von IOG Austria erhalten. Hiermit akzeptiere ich die Datenschutzerklärung."
                required
            ></v-checkbox>
            <div color="error">{{ errorMessage }}</div>
            <v-spacer></v-spacer>
          </v-form>

        </v-card-text>
        <v-card-actions>
          <v-btn color="error" @click="closeDialog">Abbrechen</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="subscribe" :disabled="!valid">Anmelden</v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>
<script>
const url = new URL(
    'https://api.sender.net/v2/subscribers',
);

const headers = {
  'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiNWQ5MDEwMjIxNmMyNzkyNmE1MzgzZmQ2NGQ1MmZkZTgwMmE5MTc0ZWVlNjdhYTQzMDdjODAzOTUxNTFhYTYxYTIxY2Q4OGExMTM1MzVlNTYiLCJpYXQiOiIxNjE3MjgyNzc1LjEyOTQzNyIsIm5iZiI6IjE2MTcyODI3NzUuMTI5NDQ2IiwiZXhwIjoiNDc3MDg4NjM3NS4xMjYxNjciLCJzdWIiOiI4NTIzNSIsInNjb3BlcyI6W119.CmpMY_z6TjiYW4qEQgx7d2tKuOGOcHv0s2IfjXOs1HzLxRRfGalRZWZUaM2Nhvln-AyGScWYSxNDtgUtCQtVKA',
  'Content-Type': 'application/json',
  'Accept': 'application/json',
};

export default {
  name: 'newsletter-abonnieren',
  data: () => ({
    valid: true,
    firstName: '',
    firstNameRules: [
      v => !!v || 'Vorname fehlt',
    ],
    lastName: '',
    lastNameRules: [
      v => !!v || 'Nachname fehlt',
    ],
    email: '',
    emailRules: [
      v => !!v || 'E-Mail wird benötigt',
      v => /.+@.+\..+/.test(v) || 'Ungültige E-Mail Adresse',
    ],
    select: null,
    newsletterDialog: false,
    checkbox: false,
    errorMessage: '',
    regionalGruppen: [
      {name: 'RG Graz', checked: false, id: 'erRYRL'},
      {name: 'RG Leoben', checked: false, id: 'eZVWYg'},
      {name: 'RG Wien', checked: false, id: 'eXoMLV'},
      {name: 'RG Tirol', checked: false, id: 'bYvMWY'},
    ],
  }),

  methods: {
    closeDialog() {
      this.firstName = '';
      this.lastName = '';
      this.email = '';
      this.newsletterDialog = false;
    },
    buildTags() {
      let tags = this.regionalGruppen.map(rg => (rg.checked) ? rg.id : null).filter(tag => tag);
      if (this.checkbox) {
        tags.push('bqY2Yy'); // Allgemein
        tags.push('bkZoON'); // Required for double opt-in logic
      } else {
        return [];
      }
      return tags;
    },
    async subscribe() {
      this.errorMessage = '';
      this.$refs.form.validate();
      if (!this.valid) return;
      let body = {
        'email': this.email,
        'firstname': this.firstName,
        'lastname': this.lastName,
        'groups': this.buildTags(),
      };
      let response = await fetch(url, {
        method: 'POST',
        headers,
        body: JSON.stringify(body),
      });
      if (response.status !== 200) {
        console.error(response.statusText);
      }
      this.closeDialog();
    },
  },
};
</script>
<style>
/* clear */

img {
  max-width: 100%;
  vertical-align: bottom;
}

a {
  color: #444;
  text-decoration: none;
}

a:hover {
  color: #444;
}

a:focus {
  outline: 0;
  text-decoration: none;
}

a:hover,
a:active {
  text-decoration: none;
  outline: 0;
}

input:focus {
  outline: 0;
  border: 1px solid #04A4CC;
}

body {
  font-family: 'Roboto', 'Calibri', sans-serif;
  font-weight: 400;
}

h1,
h2,
h3 {
  font-family: 'Lato', 'Roboto', 'Calibri', sans-serif;
  font-weight: 700;
  color: #f7a600;
}

h1 {
  text-transform: uppercase;
  font-size: 3em;
}

.nav a {
  padding-right: 1em;
  text-decoration-line: none;
}

.nav a:hover {
  color: white;
}

h1, h2, h3, h4, h5, h6 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}

h1, h2, h3 {
  font-family: 'Lato', 'Roboto', 'Calibri', sans-serif;
  font-weight: 700;
  color: #f7a600;
}

h2, h3 {
  font-weight: 600;
}

h3 {
  font-size: 24px;
  margin-top: 50px;
  margin-bottom: 50px;
  text-transform: uppercase;
  color: black;
  letter-spacing: 2px;
}

</style>