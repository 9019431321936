import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import minifyTheme from 'minify-css-string';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#f7a600',
                secondary: '#0066ff',
                accent: '#8c9eff',
                error: '#b71c1c',
            },
        },
        options: {minifyTheme, customProperties: true},
    },
});
